@font-face {
  font-family: 'Tasa Explorer';
  src: url('assets/fonts/TASAExplorerVF.woff2') format('woff2');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}

// .reference{
//   color: black;

//   @media screen and (max-width: $break-small){
//     
//   }
// }




$break-small: 850px;
$site-margin: 40px;
$site-margin-mobile: 25px;
$radius: 50px;



html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
  color: white;

  font-family: "Tasa Explorer";
  font-weight: 600;
}

header{
  padding: $site-margin;

  @media screen and (max-width: $break-small){
    padding: $site-margin-mobile ;
  }

  position: fixed;

  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100svh;

  .hero-image{
    width: 100%;
    // height: calc(100vh - (2 * $site-margin));

    flex-grow: 1;
  
    background-image: url(assets/images/bunbuns-hero.jpg);
    background-position: center center;
    background-size: cover;

    @media screen and (max-width: $break-small){
      background-image: url(assets/images/bunbuns-hero-mobile.jpg);
    }

    border-radius: $radius;

  }

  .desktop-svg{
    width: calc(100vw - (2 * $site-margin));

    position: relative;
    bottom: 0;
    margin-top: $site-margin;

    @media screen and (max-width: $break-small){
      width: calc(100vw - (2 * $site-margin-mobile));
      margin-top: $site-margin-mobile;

      display: none;
    }

    .path{
      animation: entrance 1000ms ease forwards;
      transform: translate(0px, 100%);
    }
  }

  .mobile-svg{
    width: calc(100vw - (2 * $site-margin));

    position: relative;
    bottom: 0;
    margin-top: $site-margin;

    display: none;

    @media screen and (max-width: $break-small){
      width: calc(100vw - (2 * $site-margin-mobile));
      margin-top: $site-margin-mobile;

      display: block;
    }

    .path{
      animation: entrance 1000ms ease forwards;
      transform: translate(0px, 100%);
    }
  }
}

@keyframes entrance {
  from {transform: translate(0px, 100%);}
  to {transform: translate(0,0);}
}


@for $i from 1 through 7 {
  svg .path:nth-child(#{$i}n) {
      animation-delay: #{$i * 0.15}s;
  }
}



.kitty-cutout{
  position: absolute;
  top: -134px;
  left: 105px;

  @media screen and (max-width: $break-small){
    width: 175px;
    top: -111px;
    left: 50px;
  }
}


section{
    background-color: rgba(255, 255, 255, .15);  
    backdrop-filter: blur(100px);

    width: calc(100vw - (3 * $site-margin));
    padding: calc(2 * $site-margin);

    @media screen and (max-width: $break-small){
      width: 100%;
      padding: calc(1 * $site-margin-mobile)
    }

    margin-top: 100svh;
    margin-left: auto; 
    margin-right: auto; 
    left: 0; right: 0;

    border-top-left-radius: $radius;
    border-top-right-radius: $radius;

    display: block;
    position: absolute;
    z-index: 10;

    .content{
      width: 50%;
      margin-left: 50%;

      @media screen and (max-width: $break-small){
        width: 100%;
        margin-left: 0;
        margin-top: $site-margin;
      }
    }
}

p{
  font-size: clamp(24px, 2.2vw, 34px);

  em{
    font-style: normal;
    opacity: .6;
  }
}




// NEWSLETTER

.signup-title{
  color: #FFDD31;
}

.newsletter {
  width: 100%;
  margin: calc(3 * $site-margin) 0 0 ;

  @media screen and (max-width: $break-small){
    margin: calc(3 * $site-margin-mobile) 0 0 ;
  }


  form {
    position: relative;
    text-align: center;
    display: block;
    margin: 0 auto;

    font-family: "Tasa Explorer";
    font-weight: 500;
    font-size: 24px;

    @media (max-width: $break-small) {
      display: block;
    }

    input {
      border: none;
      background-color: rgba(255, 255, 255, .15);
      width: 100%;
      padding: calc($site-margin / 2) $site-margin ;

      @media screen and (max-width: $break-small){
        padding: calc($site-margin / 2) $site-margin-mobile ;
      }

      border-radius: $radius;

      color: white;

      @media (max-width: $break-small) {

      }


      &::placeholder  {
        opacity: 1;
        color: white;
      }


      &:focus::placeholder {
        color: rgba($color: #ffffff, $alpha: 0.25);
      }

      &:focus {
        outline: none;
        border: none;

        @media (max-width: $break-small) {
        }
      }
    }

    .klaviyo_messages {
      text-align: left;

      & > div {
        margin: 20px 0 0 ;
      }
    }

    .klaviyo_form_actions{
      position: absolute;
      top: 4px;
      right: 68px;

      @media (max-width: $break-small) {
        top: 6px;
        right: 86px;
      }
    }

    button {
      border: none;
      background-color: transparent;
      position: absolute;
      cursor: pointer;

      &:hover {
        transition: opacity ease 400ms;
        opacity: 0.75;
      }
    }
  }
}